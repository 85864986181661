import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NetworkStatus = ({ excludedRoutes }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const location = useLocation();

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Intercepter l'événement beforeunload
    const handleBeforeUnload = (event) => {
      if (!isOnline) {
        event.preventDefault();
        event.returnValue = ''; // Certains navigateurs nécessitent cette ligne pour afficher la boîte de dialogue
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isOnline]);

  // Ne pas afficher le message si la route actuelle est dans excludedRoutes
  if (excludedRoutes.includes(location.pathname) || isOnline) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
      يبدو أن هناك انقطاعًا في خدمة الإنترنت. يُرجى التأكد من أن الاتصال بالشبكة يعمل بشكل صحيح، أو التحقق من إعدادات الراوتر وإعادة تشغيله إذا لزم الأمر
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fond gris transparent
    zIndex: 999, // Assurez-vous que l'overlay est au-dessus du contenu
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto', // Permet à l'overlay de capturer les clics
    cursor: 'not-allowed', // Curseur "interdit" (cercle barré)
  },
  popup: {
    backgroundColor: 'rgba(255, 0, 0, 0.8)', // Fond rouge semi-transparent
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    pointerEvents: 'auto', // Permet les clics sur le message lui-même
  },
};

export default NetworkStatus;