import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatisticADH } from "../../../Redux/Actions/adherantAction";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { getStatisticappros } from "../../../Redux/Actions/userAction";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const FarmerStat = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const [validated, setValidated] = useState(false);
  const approlist = useSelector(state => state.userReducer.statAppro);
  const [dependency, setDependency] = useState(user.dependency);
  const ADHList = useSelector(state => state.adherantReducer.StatisticADH);
  const [proxy, setProxy] = useState("");
  const [listAdh, setListAdh] = useState(ADHList);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (user?.typeofaccount === "Approvisionnement") {
      setProxy(user.name);
    }
  }, [user]);

  useEffect(() => {
    setListAdh(ADHList);
  }, [ADHList]);

  useEffect(() => {
    if (dependency) {
      dispatch(getStatisticappros({ dependency: dependency }));
    }
  }, [dependency]);

  useEffect(() => {
    if (proxy) {
      dispatch(getStatisticADH({ proxy: proxy }));
    }
  }, [proxy]);

  const handleSearch = () => {
    if (searchTerm) {
      const filteredResults = listAdh
        .map((el, index) => ({ ...el, originalIndex: index }))
        .filter(el => {
          const cin = String(el.cin || "");
          const name = String(el.name || "");
          const lastname = String(el.lastname || "");
          
          return cin.includes(searchTerm) ||
                 name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                 lastname.toLowerCase().includes(searchTerm.toLowerCase());
        });
      setSearchResults(filteredResults);
    } else {
      setSearchResults(listAdh);
    }
  };

  const affichageDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  };
  

  let totalSomme1 = 0;
  let totalSomme2 = 0;

  (searchResults.length > 0 ? searchResults : listAdh)?.forEach((el) => {
    const somme1 = Math.round(
      ((((el.chevre + el.mouton) * 8.25 +
        el.vache * 60 +
        (el.cheval + el.chameau) * 21)) /
        50)
    ) > 0 ? Math.round(
      ((((el.chevre + el.mouton) * 8.25 +
        el.vache * 60 +
        (el.cheval + el.chameau) * 21)) /
        50)
    ) : 1;

    const somme2 = Math.round(
      ((((el.chevre + el.mouton) * 8.25 +
        el.vache * 60 +
        (el.cheval + el.chameau) * 21) * 0.3) /
        50)
    ) > 0 ? Math.round(
      ((((el.chevre + el.mouton) * 8.25 +
        el.vache * 60 +
        (el.cheval + el.chameau) * 21) * 0.3) /
        50)
    ) : 1;

    totalSomme1 += somme1;
    totalSomme2 += somme2;
  });

  const downloadPDF = () => {
    setTimeout(() => {
      const input = document.getElementById('table-to-export');
      if (!input) {
        console.error("Table element not found");
        return;
      }
  
      html2canvas(input, { useCORS: true, logging: false })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgWidth = 210;
          const pageHeight = 295;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = 0;
  
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
  
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
  
          pdf.save(`${proxy}_قائمة الفلاحين لدى.pdf`);
        })
        .catch((error) => console.error("Error generating PDF: ", error));
    }, 500); // Give enough time for rendering
  };
  

  return (
    <div style={{ marginBottom: "550px" }}>
      <Form
        noValidate
        validated={validated}
        className="p-4 rounded text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1 style={{ marginTop: "100px", marginBottom: "105px" }}>
          فضاء احصائيات الفلاحين
        </h1>
        <Form.Group style={{ marginTop: "30px" }} as={Row} className="mb-3">
          <Form.Label column md={2} className="text-end order-md-2">
            الاتحادات المحلية
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            {user?.typeofaccount === "delegation" || user?.typeofaccount === "Unionlocal" ? (
              <Form.Select className="text-end" placeholder="ــ اختيار ــ" value={user.dependency}>
                <option>ــ اختيار ــ </option>
                <option value="1">بنقردان</option>
                <option value="2">بني خداش</option>
                <option value="3"> جربة ميدون</option>
                <option value="4"> جربة أجيم</option>
                <option value="5">سيدي مخلوف</option>
                <option value="6"> جربة حومة السوق</option>
                <option value="7"> جرجيس</option>
                <option value="8">مدنين الجنوبية</option>
                <option value="9">مدنيـن الشمالية</option>
              </Form.Select>
            ) : (
              <Form.Select className="text-end" placeholder="ــ اختيار ــ" onChange={(e) => setDependency(e.target.value)}>
                <option>ــ اختيار ــ </option>
                <option value="1">بنقردان</option>
                <option value="2">بني خداش</option>
                <option value="3"> جربة ميدون</option>
                <option value="4"> جربة أجيم</option>
                <option value="5">سيدي مخلوف</option>
                <option value="6"> جربة حومة السوق</option>
                <option value="7"> جرجيس</option>
                <option value="8">مدنين الجنوبية</option>
                <option value="9">مدنيـن الشمالية</option>
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        <Form.Group style={{ marginTop: "70px" }} as={Row} className="mb-3">
          <Form.Label column md={2} className="text-end order-md-2">
            تجار الأعلاف / النيابات
          </Form.Label>
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            {user?.typeofaccount === "Approvisionnement" ? (
              <Form.Select className="text-end" placeholder="ــ اختيار ــ" value={user?.name}>
                <option>ــ اختيار ــ </option>
                {approlist?.map((el) => (
                  <option key={el._id} value={el.name}>
                    {el.name}
                  </option>
                ))}
              </Form.Select>
            ) : (
              <Form.Select className="text-end" placeholder="ــ اختيار ــ" onChange={(e) => setProxy(e.target.value)}>
                <option>ــ اختيار ــ </option>
                {approlist?.map((el) => (
                  <option key={el._id} value={el.name}>
                    {el.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        <div style={{ height: '400px', overflowY: 'auto', marginTop: '100px' }}>
          <Table id="table-to-export" striped bordered hover variant="white" responsive="md" dir="rtl" style={{ marginTop: '10px' }}>
            <thead>
              <tr>
                <th colSpan="10">
                  <div style={{ display: 'flex' }}>
                    <input
                      type="text"
                      placeholder="ابحث حسب ب.ت.و ..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ marginBottom: '1px', width: '100%', padding: '5px', color: 'black' }}
                    />
                    <Button onClick={handleSearch} style={{ marginRight: '10px' }}>
                      ابحث
                    </Button>
                  </div>
                </th>
              </tr>
              <tr>
                <th></th>
                <th>رقم ب.ت.و</th>
                <th>الاسم</th>
                <th>اللقب</th>
                <th>الهاتف</th>
                <th>حصة الشعير</th>
                <th>حصة السداري</th>
                <th> تزوده في الشعير</th>
                <th>تزوده في السداري </th>
                <th> تاريخ التسجيل </th>
              </tr>
            </thead>
            <tbody>
              {(searchResults.length > 0 ? searchResults : listAdh)?.map((el, index) => {
                const displayIndex = el.originalIndex !== undefined ? el.originalIndex + 1 : index + 1;

                const somme1 = Math.round(
                  ((((el.chevre + el.mouton) * 8.25 +
                    el.vache * 60 +
                    (el.cheval + el.chameau) * 21)) /
                    50)
                ) > 0 ? Math.round(
                  ((((el.chevre + el.mouton) * 8.25 +
                    el.vache * 60 +
                    (el.cheval + el.chameau) * 21)) /
                    50)
                ) : 1;

                const somme2 = Math.round(
                  ((((el.chevre + el.mouton) * 8.25 +
                    el.vache * 60 +
                    (el.cheval + el.chameau) * 21) * 0.3) /
                    50)
                ) > 0 ? Math.round(
                  ((((el.chevre + el.mouton) * 8.25 +
                    el.vache * 60 +
                    (el.cheval + el.chameau) * 21) * 0.3) /
                    50)
                ) : 1;

                return (
                  <tr key={el._id}>
                    <td style={{ width: '50px' }}>{displayIndex}</td>
                    <td>{el.cin}</td>
                    <td>{el.name}</td>
                    <td>{el.lastname}</td>
                    <td>{el.phone}</td>
                    <td>{somme1}</td>
                    <td>{somme2}</td>
                    <td>{el.countO}</td>
                    <td>{el.countC}</td>
                    <td>{el.dateInscrit ? affichageDate(el.dateInscrit) : "01/01/2024"}</td>
                  </tr>
                );
              })}
              <tr>
                <th colSpan="8">مجموع حصة الشعير</th>
                <th colSpan="2">{totalSomme1}</th>
              </tr>
              <tr>
                <th colSpan="8">مجموع حصة السداري</th>
                <th colSpan="2">{totalSomme2}</th>
              </tr>  
            </tbody>
          </Table>
        </div>

        <Button onClick={downloadPDF} style={{ marginTop: '20px' }}>
          تحميل القائمة
        </Button>
      </Form>
    </div>
  );
};

export default FarmerStat;