import { Route, Routes, useLocation } from "react-router-dom";
import './App.css';
import Error from './Components/Error/Error';
import Navb from './Components/NavBar/Navb';
import AdherantModifier from "./Pages/GestionAdherent/AdherantModifier";
import ApprovisionnementAjouter from "./Pages/GestionApprovisionnement/ApprovisionnementAjouter";
import ApprovisionnementModifier from "./Pages/GestionApprovisionnement/ApprovisionnementModifier";
import Adherant from "./Pages/GestionAdherent/Adherant";
import AdherantAjouter from "./Pages/GestionAdherent/AdherantAjouter";
import Approvisionnement from "./Pages/GestionApprovisionnement/Approvisionnement";
import Login from "./Pages/LoginRigester/Login";
import Register from "./Pages/LoginRigester/Register";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { current } from "./Redux/Actions/userAction";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import GestionSms from "./Pages/GestionSMS/GestionSms";
import ConfirmationSmsUn from "./Pages/ConfirmationSMS/ConfirmationSmsUn";
import SmsOrge from "./Pages/GestionSMS/SmsOrge";
import SmsCedra from "./Pages/GestionSMS/SmsCedra";
import ConfirmationOrge from "./Pages/ConfirmationSMS/ConfirmationOrge";
import ConfirmationCedra from "./Pages/ConfirmationSMS/ConfirmationCedra";
import Statistics from "./Pages/Statistics/Statistics";
import FarmerStat from "./Pages/Statistics/StatisticsTable/FarmerStat";
import ApproStat from "./Pages/Statistics/StatisticsTable/ApproStat";
import ConfirmSmsStat from "./Pages/Statistics/StatisticsTable/ConfirmSmsStat";
import FarmerOrgeStat from "./Pages/Statistics/StatisticsTable/FarmerOrgeStat/FarmerOrgeStat";
import FarmerCedraStat from "./Pages/Statistics/StatisticsTable/FarmerCedraStat/FarmerCedraStat";
import SmsType from "./Pages/GestionSMS/SmsType";
import SuppGestionSms from "./Pages/GestionSMS/SupplementarySms/SuppGestionSms";
import SuppSmsOrge from "./Pages/GestionSMS/SupplementarySms/SuppSmsOrge";
import SuppSmsCedra from "./Pages/GestionSMS/SupplementarySms/SuppSmsCedra";
import UnStat from "./Pages/Statistics/StatisticsTable/UnStat";
import ComingSoon from "./Components/ComingSoon/Comingsoon";
import EtatSmsSend from "./Pages/EtatSMSEnvoyer/EtatSmsSend";
import NetworkStatus from './NetworkStatus'; 

function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      dispatch(current());
    }
  }, [dispatch]);

  const user = useSelector(state => state.userReducer.user);

  // Liste des routes où le NetworkStatus ne doit pas s'afficher
  const excludedRoutes = [
    '/FarmerStat',
    '/ApproStat',
  ];

  return (
    <div>
      <div className="App">
        <Navb />
        <NetworkStatus excludedRoutes={excludedRoutes} /> {/* Passez les routes exclues ici */}
        <Routes>
          <Route path='/' element={<Home />} />
          {/* Home */}

          {/* Login / Register Routes */}
          <Route path='/Login' element={<Login />} />
          {user?.typeofaccount == "Admin" ? <Route path='/Register' element={<Register />} /> : null}

          {/* other Pages Routes */}
          {/* Adherant Pages */}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/Adherant' element={<Adherant />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path="/AdherantAjouter" element={<AdherantAjouter />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/AdherantModifier' element={<AdherantModifier />} /> : null}

          {/* Approvisionnemnt Pages Routes */}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/Approvisionnement' element={<Approvisionnement />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/ApprovisionnementAjouter' element={<ApprovisionnementAjouter />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/ApprovisionnementModifier' element={<ApprovisionnementModifier />} /> : null}

          {/* GestionSms */}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/GestionSms' element={<GestionSms />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/SuppGestionSms' element={<SuppGestionSms />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/SmsType' element={<SmsType />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path="/SmsOrge" element={<SmsOrge />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/SmsCedra' element={<SmsCedra />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path="/SuppSmsOrge" element={<SuppSmsOrge />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path="/SuppSmsCedra" element={<SuppSmsCedra />} /> : null}

          {/* Statics */}
          {user ? <Route path='/Statistics' element={<Statistics />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" || user?.typeofaccount == "delegation" || user?.typeofaccount == "Control" ? <Route path='/FarmerStat' element={<FarmerStat />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" || user?.typeofaccount == "delegation" || user?.typeofaccount == "Control" ? <Route path='/ApproStat' element={<ApproStat />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" || user?.typeofaccount == "delegation" ? <Route path='/UnStat' element={<UnStat />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" || user?.typeofaccount == "delegation" || user?.typeofaccount == "Control" ? <Route path='/ConfirmSmsStat' element={<ConfirmSmsStat />} /> : null}
          {user ? <Route path='/FarmerOrgeStat' element={<FarmerOrgeStat />} /> : null}
          {user ? <Route path='/FarmerCedraStat' element={<FarmerCedraStat />} /> : null}

          {/* Receivedsms */}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" || user?.typeofaccount == "Approvisionnement" ? <Route path='/ConfirmationSmsUn' element={<ConfirmationSmsUn />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" || user?.typeofaccount == "Approvisionnement" ? <Route path='/ConfirmationOrge' element={<ConfirmationOrge />} /> : null}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" || user?.typeofaccount == "Approvisionnement" ? <Route path='/ConfirmationCedra' element={<ConfirmationCedra />} /> : null}

          {/* Statics */}
          {user?.typeofaccount == "Admin" || user?.typeofaccount == "Unionlocal" ? <Route path='/EtatSMS' element={<EtatSmsSend />} /> : null}

          <Route path='/*' element={<Error />} />
        </Routes>

        <Footer />

        <ToastContainer
          position="top-center"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="dark"
        />
      </div>
    </div>
  );
}

export default App;