import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const ModalApproStat = (props) => {
  const ADHList = useSelector(state => state.adherantReducer.StatisticADH);

  const [show, setShow] = useState(false);
  const [showHistorique, setShowHistorique] = useState(false); // État pour le second modal
  const [listAdh, setListAdh] = useState(ADHList);
  const [searchTerm, setSearchTerm] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [restO, setRestO] = useState(0);
  const [qtO, setQtO] = useState(0);
  const [totalRestCamionO, setTotalRestCamionO] = useState(0);
  const [restC, setRestC] = useState(0);
  const [qtC, setQtC] = useState(0);
  const [totalRestCamionC, setTotalRestCamionC] = useState(0);
  const [supp, setSupp] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // second modal
  const handleCloseHistorique = () => setShowHistorique(false);
  const handleShowHistorique = () => setShowHistorique(true);

  const handleSearch = () => {
    if (searchTerm) {
      setListAdh(listAdh.filter(e => e.cin == searchTerm));
    } else {
      setListAdh(ADHList);
    }
  };

  const affichageDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleYearChange = (e) => setSelectedYear(e.target.value);
  const handleMonthChange = (e) => setSelectedMonth(e.target.value);
  const handleTypeChange = (e) => setSelectedType(e.target.value);

  const applyFilters = (data) => {
    return data.filter(el => {
      const dateObj = new Date(el.date);
      const year = dateObj.getUTCFullYear();
      const month = dateObj.getUTCMonth() + 1;
      const typeMatch = selectedType === '' || el.type === selectedType;
      const yearMatch = selectedYear === '' || year === parseInt(selectedYear);
      const monthMatch = selectedMonth === '' || month === parseInt(selectedMonth);
      return typeMatch && yearMatch && monthMatch;
    });
  };

  // principale modal
  useEffect(() => {
    const filteredHistorique = applyFilters(props?.el?.historique || []);
    const total = filteredHistorique?.reduce((acc, el) => acc + parseFloat(el.qt), 0) || 0;
    setTotalQuantity(total);
  }, [selectedYear, selectedMonth, selectedType, props?.el?.historique, supp]);

  const filteredHistorique = applyFilters(props?.el?.historique || []);

  // Second modal
  useEffect(() => {
    const filteredHistoriqueSecond = applyFilters(props?.el?.historique || []);
    const total = filteredHistoriqueSecond?.reduce((acc, el) => acc + parseFloat(el.qt), 0) || 0;
    setTotalQuantity(total);
  }, [selectedYear, selectedMonth, selectedType, props?.el?.historique, supp]);

  const filteredHistoriqueSecond = applyFilters(props?.el?.historique || []);

  useEffect(() => {
    if (props.el) {
      setRestO(props.el.restO || 0);
      setQtO(props.el.qtO || 0);
      setTotalRestCamionO(props.el.restDistributionOrge || 0);
    }
  }, [props.el]);

  useEffect(() => {
    if (props.el) {
      setRestC(props.el.restC || 0);
      setQtC(props.el.qtC || 0);
      setTotalRestCamionC(props.el.restDistributionCedra || 0);
    }
  }, [props.el]);

  //pour formater la date comme je veux soit jj-mm-aaaa hh:mm utiliser cet fct lors d'affichage date de sauvegarde
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  

  return (
    <div>
      <Button variant="info" onClick={handleShow}>
        احصائيات
      </Button>

      {/* Principal Modal  */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header closeButton className="text-center flex-column flex-md-row align-items-center">
          <Modal.Title className="w-100 mb-3 mb-md-0">
            احصائيات النيابة
          </Modal.Title>

          <div className="d-flex flex-column flex-md-row w-100 justify-content-center">
            <Form.Select
              aria-label="Select Type"
              onChange={handleTypeChange}
              className="mx-md-3"
              style={{ width: '200px' }}
            >
              <option value="">اختر النوع</option>
              <option value="شعير">شعير</option>
              <option value="سداري">سداري</option>
            </Form.Select>
            <Form.Select
              aria-label="Select Month"
              onChange={handleMonthChange}
              className="mx-md-3 mb-3 mb-md-0"
              style={{ width: '200px' }}
            >
              <option value="">اختر الشهر</option>
              <option value="1">جانفي</option>
              <option value="2">فيفري</option>
              <option value="3">مارس</option>
              <option value="4">أفريل</option>
              <option value="5">ماي</option>
              <option value="6">جوان</option>
              <option value="7">جويلية</option>
              <option value="8">أوت</option>
              <option value="9">سبتمبر</option>
              <option value="10">أكتوبر</option>
              <option value="11">نوفمبر</option>
              <option value="12">ديسمبر</option>
            </Form.Select>
            <Form.Select
              aria-label="Select Year"
              onChange={handleYearChange}
              className="mx-md-3 mb-3 mb-md-0"
              style={{ width: '200px' }}
              value={selectedYear}
            >
              <option value="">اختر السنة</option>
              {[2024, 2025].map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </Form.Select>
            <Button style={{ width: '117px' }} variant="info" onClick={handleShowHistorique}>
             الإحصائيات
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div>
            {selectedMonth && selectedType && selectedYear ? (
              <Table
                striped
                bordered
                hover
                variant="dark"
                responsive="md"
                dir="rtl"
                style={{ marginTop: '10px' }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>اسم تاجر الأعلاف</th>
                    <th>رقم الشاحنة</th>
                    <th>المادة</th>
                    <th>الكمية</th>
                    <th>النسبة المئوية للتوزيع</th>
                    <th>تاريخ بعث الأرساليات</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredHistorique.map((el, index) => (
                    <tr key={index}>
                      <td style={{ width: '50px' }}>{index + 1}</td>
                      <td>{props.el.name}</td>
                      <td>{el.camionNumber}</td>
                      <td>{el.type}</td>
                      <td>{el.qt}</td>
                      <td>{el.pourcentage * 100 + "%"}</td>
                      <td>{affichageDate(el.date)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center" style={{ marginTop: '20px', color: '#ccc' }}>
                الرجاء اختيار السنة، الشهر والنوع لعرض الإحصائيات
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex flex-column align-items-center">
        {selectedType === "شعير" && (
              (() => {
                const filteredData = props.el.Histoniyabamois.filter(item => {
                  const date = new Date(item.date);
                  return (
                    date.getUTCFullYear() === parseInt(selectedYear) &&
                    date.getUTCMonth() + 1 === parseInt(selectedMonth)
                  );
                });

                if (filteredData.length === 0) {
                  return (
                    <div className="text-danger text-center w-100">
                      يمكننا الحصول على الإحصائيات المتعلقة بالشعير الذي تم توزيعه والذي تبقى منه، سواء من التوزيع أو من الكمية المتبقية، فقط في آخر يوم من كل شهر. بذلك يمكننا تقييم كل تاجر ومعرفة الكمية التي لديه وإذا كنت ترغب في معرفة الكميات الحالية لكل تاجر بشكل دقيق، فعليك الدخول إلى صفحة الإحصائيات لتأكيد الاستلام
                    </div>
                  );
                }

                return (
                  <>
                    <h5 className="text-center">الشعير</h5>
                    <div className="text-center mb-2">
                      عدد الأكياس الجملية حسب كمية العلف الصادرة في كل شاحنة لهذا الشهر: {totalQuantity}
                    </div>
                    <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '0.9rem', width: '100%' }}>
                      {filteredData.map((item, index) => (
                        <React.Fragment key={index}>
                          {/* ce button affiche la somme des orgeditribue selon month year */}
                          <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
                            الكمية الشعير الموزعة: {
                              (props.el.TotalOrgeDistribueHorodatage || [])
                                .filter(entry => {
                                  const entryDate = new Date(entry.date);
                                  return (
                                    entryDate.getUTCFullYear() === parseInt(selectedYear) &&
                                    entryDate.getUTCMonth() + 1 === parseInt(selectedMonth)
                                  );
                                })
                                .reduce((total, entry) => total + (entry.OrgeDistribue || 0), 0)
                            }
                          </button>
                          <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
                            الكمية الشعير المتبقية من التوزيع: {item.HistoRestO || 0}
                          </button>
                          <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
                            الكمية الشعير المتبقية عند بعث الإرساليات: {item.HistoRestDistributionOrge || 0}
                          </button>
                          <div className="text-danger text-center w-100">
                            تم تسجيل هذه الإحصائيات بتاريخ : {formatDate(item.date || '')} وهي لا تتأثر بأي شكل من الأشكال عند إجراء عملية إعادة تحيين الفلاح خلال الشهر
                          </div>


                         
                        </React.Fragment>
                        
                      ))}
                    </div>
                  </>
                );
              })()
            )}

            {selectedType === "سداري" && (
              (() => {
                const filteredData = props.el.Histoniyabamois.filter(item => {
                  const date = new Date(item.date);
                  return (
                    date.getUTCFullYear() === parseInt(selectedYear) &&
                    date.getUTCMonth() + 1 === parseInt(selectedMonth)
                  );
                });

                if (filteredData.length === 0) {
                  return (
                    <div className="text-danger text-center w-100">
                      يمكننا الحصول على الإحصائيات المتعلقة بالسداري الذي تم توزيعه والذي تبقى منه، سواء من التوزيع أو من الكمية المتبقية، فقط في آخر يوم من كل شهر. بذلك يمكننا تقييم كل تاجر ومعرفة الكمية التي لديه وإذا كنت ترغب في معرفة الكميات الحالية لكل تاجر بشكل دقيق، فعليك الدخول إلى صفحة الإحصائيات لتأكيد الاستلام
                    </div>
                  );
                }

                return (
                  <>
                    <h5 className="text-center">السداري</h5>
                    <div className="text-center mb-2">
                      عدد الأكياس الجملية حسب كمية العلف الصادرة في كل شاحنة لهذا الشهر: {totalQuantity}
                    </div>
                    <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '0.9rem', width: '100%' }}>
                      {filteredData.map((item, index) => (
                        <React.Fragment key={index}>
                          {/* ce button affiche la somme des orgeditribue selon month year */}
                          <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
                            الكمية السداري الموزعة: {
                              (props.el.TotalCedraDistribueHorodatage || [])
                                .filter(entry => {
                                  const entryDate = new Date(entry.date);
                                  return (
                                    entryDate.getUTCFullYear() === parseInt(selectedYear) &&
                                    entryDate.getUTCMonth() + 1 === parseInt(selectedMonth)
                                  );
                                })
                                .reduce((total, entry) => total + (entry.CedraDistribue || 0), 0)
                            }
                          </button>
                          <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
                            الكمية السداري المتبقية من التوزيع: {item.HistoRestC || 0}
                          </button>
                          <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
                            الكمية السداري المتبقية عند بعث الإرساليات: {item.HistoRestDistributionCedra || 0}
                          </button>
                          <div className="text-danger text-center w-100">
                            تم تسجيل هذه الإحصائيات بتاريخ : {formatDate(item.date || '')} وهي لا تتأثر بأي شكل من الأشكال عند إجراء عملية إعادة تحيين الفلاح خلال الشهر
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                );
              })()
            )}
        </Modal.Footer>

        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleClose}>
            اغلاق
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Second Modal for Historique */}
      <Modal
        show={showHistorique}
        onHide={handleCloseHistorique}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>جميع الإحصائيات حسب إختيار  السنة، الشهر أو نوع العلف</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped bordered hover variant="dark" responsive="md" dir="rtl">
            <thead>
              <tr>
                <th></th>
                <th>اسم تاجر الأعلاف</th>
                <th>رقم الشاحنة</th>
                <th>المادة</th>
                <th>الكمية</th>
                <th>النسبة المئوية للتوزيع</th>
                <th>تاريخ بعث الأرساليات</th>
              </tr>
            </thead>
            <tbody>
              {filteredHistoriqueSecond.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{props.el.name}</td>
                  <td>{el.camionNumber}</td>
                  <td>{el.type}</td>
                  <td>{el.qt}</td>
                  <td>{el.pourcentage * 100 + "%"}</td>
                  <td>{affichageDate(el.date)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseHistorique}>
            اغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalApproStat;
