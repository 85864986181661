import React, { useEffect, useState } from 'react'
import { Bar, Pie} from 'react-chartjs-2';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';

import './UnStat.css';
import axios from 'axios'
import { useSelector } from 'react-redux';


const UnStat = () => {

  Chart.register(CategoryScale);
  const user=useSelector(state=>state.userReducer.user)
  const [dataadherant, setDataAdherant] = useState(null);
  const [DataAppro, setDataAppro] = useState(null);
    const [totalorgeData, setTotalOrgeData] = useState(null);
    const [totalcedraData, setTotalCedraData] = useState([]);
    const [totalcData, setTotalCData] = useState();
    const [orgemoazaaData, setOrgeMoazaaData] = useState([]);
    const [cedramoazaaData, setCedraMoazaaData] = useState([]);
    const [orgeresteData, setOrgeResteData] = useState([]);
    const [cedrresteData, setCedraResteData] = useState([]);


    useEffect(() => {
      fetchDataAdherant();
      fetchDataAppro()
      fetchDataTotalOrge()
      fetchDataTolalCedra()
      fetchDataResteOrgeAndCedra()
      fetchDataMoazaaOrgeAndCedra()
      
    }, []);

    const fetchDataAdherant = async () => {
      try {
        let response = await axios.post('/api/Adherant/dataAdherant', {
          dependency: user?.dependency,
        });
    
        let backendData = response?.data;
        let adherantsData = backendData?.adherants || [];
        let yearsData = backendData?.yearsData || [];
        // let totalAppro = backendData?.totalCount;
    
        // Créer un objet pour regrouper les données des années
        let countsByYear = {};
    
        yearsData.forEach((yearData) => {
          const year = yearData.year || 2024; // Utiliser 2024 pour les années nulles
          countsByYear[year] = (countsByYear[year] || 0) + yearData.count;
        });
    
        // Extraire les labels et les valeurs des années
        let yearsLabels = Object.keys(countsByYear).map((year) => `السنة ${year}`);
        let yearsCounts = Object.values(countsByYear);
    
        // Extraire les données mensuelles
        let months = adherantsData.map((data) => data?.month);
        let counts = adherantsData.map((data) => data?.count);
    
        // Combiner les données annuelles, mensuelles et le total
        //dans le cas ou nous voudrons afficher le totalcount
        // const finalLabels = [...yearsLabels, ...months, 'مجموع الفلاحة'];
        // const finalCounts = [...yearsCounts, ...counts, totalAppro];
        const finalLabels = [...yearsLabels, ...months];
        const finalCounts = [...yearsCounts, ...counts];
    
        // Mettre à jour l'état des données pour le graphique
        setDataAdherant({
          labels: finalLabels,
          datasets: [
            {
              label: 'عدد الفلاحة',
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgba(0,0,0,1)',
              borderWidth: 2,
              data: finalCounts,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching adherants data:', error);
      }
    };
    
// *******************************************************************************************************
const fetchDataAppro = async () => {
  try {
    let response = await axios.post('/api/user/dataAppro', {
      dependency: user?.dependency,
    });

    let backendData = response?.data;
    let monthsData = backendData?.Appro || [];
    let yearlyData = backendData?.yearlyData || [];
    // let totalAppro = backendData?.totalCount;

    // Créer un objet pour regrouper les données des années
    let countsByYear = {};

    yearlyData.forEach((yearData) => {
      const year = yearData.year || 2024; // Utiliser 2024 pour les années nulles
      countsByYear[year] = (countsByYear[year] || 0) + yearData.count;
    });

    // Extraire les labels et les valeurs des années
    let yearsLabels = Object.keys(countsByYear).map((year) => `السنة ${year}`);
    let yearsCounts = Object.values(countsByYear);

    // Extraire les données mensuelles
    let months = monthsData.map((data) => data?.month);
    let counts = monthsData.map((data) => data?.count);

    // Combiner les données annuelles, mensuelles et le total
    // lorsque je veux affiche le totalcount
    // const finalLabels = [...yearsLabels, ...months, 'مجموع النيابات'];
    // const finalCounts = [...yearsCounts, ...counts, totalAppro];
    const finalLabels = [...yearsLabels, ...months];
    const finalCounts = [...yearsCounts, ...counts];

    // Mettre à jour l'état des données pour le graphique
    setDataAppro({
      labels: finalLabels,
      datasets: [
        {
          label: 'عدد النيابات',
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: finalCounts,
        },
      ],
    });
  } catch (error) {
    console.error('Error fetching appro data:', error);
  }
};

// *******************************************************************************************************
const fetchDataTotalOrge = async () => {
  try {
    let response = await axios.post('/api/Adherant/dataOrgeTotal', {
      dependency: user?.dependency,
    });

    let backendData = response?.data || {};
    let monthsData = backendData?.adherants || [];
    let yearlyData = backendData?.yearlyData || [];
    // let totalOrge = backendData?.totalOrge || 0;

    // Créer un objet pour regrouper les données des années
    let cotaByYear = {};

    yearlyData.forEach((yearData) => {
      const year = yearData.year || 2024; // Utiliser 2024 par défaut si l'année est nulle
      cotaByYear[year] = (cotaByYear[year] || 0) + yearData.Cota;
    });

    // Extraire les labels et les valeurs des années
    let yearsLabels = Object.keys(cotaByYear).map((year) => `السنة ${year}`);
    let yearsCota = Object.values(cotaByYear);

    // Extraire les données mensuelles
    let months = monthsData.map((data) => data?.month);
    let cota = monthsData.map((data) => data?.Cota);

    // Combiner les données annuelles, mensuelles et le total
    const finalLabels = [...yearsLabels, ...months];
    const finalCounts = [...yearsCota, ...cota];

    // Mettre à jour l'état des données pour le graphique
    setTotalOrgeData({
      labels: finalLabels,
      datasets: [
        {
          label: 'العدد الجملي للشعير',
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: finalCounts,
        },
      ],
    });
  } catch (error) {
    console.error('Error fetching total orge data:', error);
  }
};

  // *******************************************************************************************************
     const fetchDataTolalCedra = async () => {
     try {
        
    
  let response=await axios.post('/api/Adherant/dataCedraTotal',{dependency:user?.dependency});
      
  let backendData = response?.data?.adherants
  let totalCedra = response?.data?.totalCedra; 
  
  setTotalCedraData( backendData);
  setTotalCData(totalCedra)
} catch (error) {
    console.error('Error fetching data:', error);
}
};

let months3 = totalcedraData?.map(data => data?.month);
let Cota1 = totalcedraData?.map(data => data?.Cota);

const datatotalcedra = {
labels:[...months3, 'كمية السداري الاجمالية'],
datasets: [
{
    label:'العدد الجملي للسداري',
    backgroundColor: 'rgba(75,192,192,1)',
    borderColor: 'rgba(0,0,0,1)',
    borderWidth: 2,
    data: [...Cota1, totalcData] // Ajouter le totalCount à la liste des counts
}
]
  };

  // *******************************************************************************************************
  const fetchDataResteOrgeAndCedra = async () => {
    try {
        // Fetching restO data
        let responseOrge = await axios.post('/api/user/restApproOrge', {dependency: user?.dependency});
        let backendDataOrge = responseOrge?.data?.restO;
        setOrgeResteData(backendDataOrge);

        // Fetching restC data
        let responseCedra = await axios.post('/api/user/restApproCedra', {dependency: user?.dependency});
        let backendDataCedra = responseCedra?.data?.restC;
        setCedraResteData(backendDataCedra);

      } catch (error) {
        console.error('Error fetching data:', error);
    }
  }
        // Prepare combined data for pie chart
        const dataResteOrgeCedra = {
            labels: ['الكمية المتبقية للشعير', 'الكمية الموزعة للشعير'],
            datasets: [
                {
                    label: 'الكمية المتبقية و الموزعة',
                    backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                    borderColor: 'rgba(0, 0, 0, 1)',
                    borderWidth: 2,
                    data: [orgeresteData, orgemoazaaData]
                }
            ]
        };
    
        const fetchDataMoazaaOrgeAndCedra = async () => {
          try {
              // Fetching restO data
              let responseOrge = await axios.post('/api/user/moazaaApproOrge', {dependency: user?.dependency});
              let backendDataOrge = responseOrge?.data?.MoazaaO;
              setOrgeMoazaaData(backendDataOrge);
      
              // Fetching restC data
              let responseCedra = await axios.post('/api/user/MoazaaApproCedra', {dependency: user?.dependency});
              let backendDataCedra = responseCedra?.data?.MoazaaC;
              setCedraMoazaaData(backendDataCedra);
      
            } catch (error) {
              console.error('Error fetching data:', error);
          }
        }
              // Prepare combined data for pie chart
              const dataMoazaaOrgeCedra = {
                  labels: ['الكمية المتبقية للسداري', 'الكمية الموزعة للسداري'],
                  datasets: [
                      {
                          label: 'الكمية المتبقية و الموزعة',
                          backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                          borderColor: 'rgba(0, 0, 0, 1)',
                          borderWidth: 2,
                          data: [cedrresteData, cedramoazaaData]
                      }
                  ]
              };
          

    
    const dataSMS = {
      labels: [' عدد الارساليات المبعوثة ',' عدد الارساليات المتبقية'],
      datasets: [
          {
              label: 'عدد الارساليات',
              backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 2,
              data: [user?.numSMS?user.numSMS:0,user?.numSMS?560000-user?.numSMS:560000]
          }
      ]
  };

    

    return (
      <div className="container50">
      {dataadherant && (
        <div className="item">
          <h2>عدد الفلاحين الاجمالي</h2>
          <Bar data={dataadherant} />
        </div>
      )}
      {DataAppro && (
        <div className="item">
          <h2>عدد النيابات الاجمالي</h2>
          <Bar data={DataAppro} />
        </div>
      )}
       {totalorgeData && (
        <div className="item">
          <h2>كمية الشعير الاجمالي</h2>
          <Bar data={totalorgeData} />
        </div>
      )}
      <div className="item">
          <h2>كمية السداري الاجمالي و حسب الشهر</h2>
          <Bar data={datatotalcedra}  />
      </div>
      <div className="item">
          <h2>الكمية المتبقية و الموزعة للشعير</h2>
          <Pie data={dataResteOrgeCedra}  />
      </div>
      <div className="item">
          <h2>الكمية المتبقية و الموزعة للسداري</h2>
          <Pie data={dataMoazaaOrgeCedra}  />
      </div>
      <div className="item">
          <h2>عدد الارساليات المبعوثة و المتبقية</h2>
          <Pie data={dataSMS}  />
      </div>
  </div>









);
};

export default UnStat;