import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getadhSMSorge, resetConfirmListAdh } from "../../Redux/Actions/adherantAction";
import ConfirmationCode from "./ConfButton/ConfirmationCode";

const ConfirmationOrge = () => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const approlist = useSelector((state) => state.userReducer.approlist);
  const user = useSelector((state) => state.userReducer.user);
  const adhlistSMSorge = useSelector(
    (state) => state.adherantReducer.adhlistSMSorge
  );
  const [listAdh, setListAdh] = useState(adhlistSMSorge);
  const [searchTerm, setSearchTerm] = useState("");
  const [proxy, setProxy] = useState(user?.name);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(24);
  const [totalCotaOrge, setTotalCotaOrge] = useState(0);

  useEffect(() => {
    setListAdh(adhlistSMSorge);
  }, [adhlistSMSorge]);

  useEffect(() => {
    return () => {
      dispatch(resetConfirmListAdh()); // Réinitialise la liste à un tableau vide
    };
  }, []);

  // Logique de pagination
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  let currentRows = listAdh?.slice(indexOfFirstRow, indexOfLastRow);

  useEffect(() => {
    currentRows = listAdh?.slice(indexOfFirstRow, indexOfLastRow);
  }, [listAdh]);

  useEffect(() => {
    const newTotalCotaOrge = listAdh?.reduce((sum, el) => {
      const cotaOrgeValue = parseFloat(el.cotaOrge) || 0;
      return sum + cotaOrgeValue;
    }, 0);
    setTotalCotaOrge(newTotalCotaOrge); // Mettre à jour l'état
  }, [listAdh]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = () => {
    if (searchTerm) {
      setListAdh(adhlistSMSorge.filter((e) => e.codeOrge === searchTerm || e.cin == searchTerm));
    } else {
      setListAdh(adhlistSMSorge);
    }
  };

  const handleDownloadPDF = async () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const rowsPerPage = 24; // Ajustez en fonction de la hauteur des lignes et de la taille de la page
    const pageHeight = pdf.internal.pageSize.height;

    const totalPages = Math.ceil(listAdh.length / rowsPerPage);

    for (let page = 1; page <= totalPages; page++) {
      setCurrentPage(page);

      // Wait for DOM updates
      await new Promise((resolve) => setTimeout(resolve, 500));

      const confirmationOrgeTable = document.querySelector(".confirmation-orge-table");

      // Capture table as image
      const canvas = await html2canvas(confirmationOrgeTable, {
        scrollX: 0,
        scrollY: -window.scrollY,
      });

      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // Largeur en mm pour A4
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add image to PDF
      if (page > 1) {
        pdf.addPage();
      }
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    }

    // Save the PDF
    pdf.save("confirmation_orge.pdf");
  };

  return (
    <div style={{ marginBottom: "150px" }}>
      <Form
        noValidate
        validated={validated}
        className="p-4 rounded text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1>فضاء تأكيد استلام اكياس الشعير</h1>
        {user?.typeofaccount === "Admin" ||
        user?.typeofaccount === "Unionlocal" ? (
          <Form.Group style={{ marginTop: "150px" }} as={Row} className="mb-3">
            <Form.Label column md={2} className="text-end order-md-2">
              تاجر الأعلاف
            </Form.Label>
            <Col
              md={{ span: 3, offset: 6 }}
              className="order-md-1"
              style={{ display: "flex" }}
            >
              <Button
                variant="primary"
                style={{ marginRight: "10px" }}
                onClick={() => dispatch(getadhSMSorge({ proxy: proxy }))}
              >
                تأكيد
              </Button>
              <Form.Select
                className="text-end"
                placeholder="ــ اختيار ــ"
                onChange={(e) => setProxy(e.target.value)}
              >
                <option>ــ اختيار ــ </option>
                {approlist?.map((el) => (
                  <option key={el._id} value={el.name}>
                    {el.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        ) : (
          <Form.Group
            style={{ marginTop: "150px" }}
            as={Row}
            className="mb-3"
          >
            <Form.Label column md={2} className="text-end order-md-2">
              تاجر الأعلاف
            </Form.Label>
            <Col
              md={{ span: 3, offset: 6 }}
              className="order-md-1"
              style={{ display: "flex" }}
            >
              <Button
                variant="primary"
                style={{ marginRight: "10px" }}
                onClick={() => dispatch(getadhSMSorge({ proxy: proxy }))}
              >
                تأكيد
              </Button>
              <Form.Select
                className="text-end"
                placeholder="ــ اختيار ــ"
                value={user?.name}
              >
                <option>ــ اختيار ــ </option>
                {approlist?.map((el) => (
                  <option key={el._id} value={el.name}>
                    {el.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        )}

        {/* Nouveau champ pour afficher la somme des cotaOrge */}
        <Form.Group style={{ marginTop: "20px" }} as={Row} className="mb-3">
          <Form.Label column md={2} className="text-end order-md-2">
          كمية الشعير المتبقية
          </Form.Label>
          <Col
            md={{ span: 3, offset: 6 }}
            className="order-md-1"
            style={{ display: "flex" }}
          >
            <Form.Control
              type="text"
              value={totalCotaOrge}
              disabled
              className="text-end"
            />
          </Col>
        </Form.Group>
      </Form>

      {/* Table */}
      <div style={{ margin: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
          <input
            type="text"
            placeholder="ابحث حسب الرمز السري ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              padding: "5px",
              color: "black",
              flex: "1",
              marginRight: "10px",
            }}
          />
          <Button onClick={handleSearch} style={{ marginRight: "10px" }}>
            ابحث
          </Button>
          <Button onClick={handleDownloadPDF}>تحميل PDF</Button>
        </div>

        <Table
          striped
          bordered
          hover
          responsive="md"
          dir="rtl"
          variant="light"
          className="confirmation-orge-table"
        >
          <thead>
            <tr>
              <th></th>
              <th>رقم ب.ت.و</th>
              <th>الاسم</th>
              <th>اللقب</th>
              <th>الكمية</th>
              {user?.typeofaccount === "Admin" ||
              user?.typeofaccount === "Unionlocal" ? (
                <th>الرمز السري </th>
              ) : null}
              <th>تأكيد الاستلام</th>
            </tr>
          </thead>
          <tbody>
            {currentRows?.map((el, index) => (
              <tr key={el._id}>
                <td style={{ width: "50px" }}>{indexOfFirstRow + index + 1}</td>
                <td>{el.cin}</td>
                <td>{el.name}</td>
                <td>{el.lastname}</td>
                <td>{el.cotaOrge}</td>
                {user?.typeofaccount === "Admin" ||
                user?.typeofaccount === "Unionlocal" ? (
                  <td>{el.codeOrge}</td>
                ) : null}
                <td dir="ltr" style={{ width: "90px" }}>
                  <ConfirmationCode
                    el={el}
                    type="شعير"
                    code={el.codeOrge}
                    date={el?.historiqueO[0]?.date}
                    period={el?.historiqueO[0]?.period}
                    searchTerm={searchTerm}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center">
          <Pagination>
            {Array.from({
              length: Math.ceil(listAdh?.length / rowsPerPage),
            }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationOrge;