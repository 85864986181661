import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ModalFarmerOrgeStat from "../FarmerOrgeStat/ModalFarmerOrgeStat";
import { getStatisticappros } from "../../../../Redux/Actions/userAction";
import { confirmedSMS, resetConfirmListAdh } from "../../../../Redux/Actions/adherantAction";
import axios from "axios";

const FarmerOrgeStat = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userReducer.user);
  const [validated, setValidated] = useState(false);
  const unionlist = useSelector(state => state.adherantReducer.confirmedList);
  const approlist = useSelector(state => state.userReducer.statAppro);
  const [dependency, setDependency] = useState(user?.dependency);
  const [proxy, setProxy] = useState(user?.proxy);
  const [listAdh, setListAdh] = useState(unionlist);
  const [appro, setAppro] = useState({});
  const [histo, setHisto] = useState([]);
  const [kimietCha3irMontahiyat, setKimietCha3irMontahiyat] = useState(0);
  // States modifiables pour mettre à jour les quantités
  const [restO,setRestO]=useState(0)
  const [restDistributionO,setRestDistributionO]=useState(0)
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  useEffect(()=>{
    return () => {
   
      dispatch(resetConfirmListAdh()); // Réinitialise la liste à un tableau vide
  };
  },[])
  useEffect(()=>{
    setRestO(appro ? appro[0]?.restO ? appro[0]?.restO : 0 : 0)
    setRestDistributionO(appro ? appro[0]?.restDistributionOrge ? appro[0].restDistributionOrge : 0 : 0)
  },[appro])
 useEffect(()=>{
  setKimietCha3irMontahiyat(0)
 },[listAdh])
  useEffect(() => {
    setAppro(approlist?.filter(el => el.name === proxy));
  }, [proxy]);

  useEffect(() => {
    if (appro) {
      setHisto(appro[0]?.historique?.filter((el) => el.type === "شعير"));
    }
  }, [appro]);

  useEffect(() => {
    if (unionlist) {
      setListAdh(unionlist);
    }
  }, [unionlist]);

  useEffect(() => {
    if (dependency) {
      dispatch(getStatisticappros({ dependency: dependency }));
    }
  }, [dependency]);

  useEffect(() => {
    if (proxy) {
      const type = "شعير";
      dispatch(confirmedSMS({ proxy: proxy, type: type }));
    }
  }, [proxy]);

  const [searchTerm, setSearchTerm] = useState(0);

  const handleSearch = () => {
    if (searchTerm) {
      setListAdh(unionlist.filter(e => e.cin == searchTerm));
    } else {
      setListAdh(unionlist);
    }
  };

  const affichageDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  };

  const calculateKimietCha3irMontahiyat = () => {
    if (listAdh?.length) {
      let qt=0
      listAdh.map(el=>{
        
        if(!el.historiqueO[0]?.confirmed){
          
          const currentDate = new Date();
          const shippingDate = new Date(el.historiqueO[0]?.date);
          const duration = currentDate - shippingDate;
          
          const quantity = el.historiqueO[0]?.period - (Math.floor(duration / (1000 * 60 * 60 * 24))); // Réduction d'une unité par jour
          if(quantity<=0){
            qt=Number(qt)+Number(el.historiqueO[0]?.cota)}
        }
        
      }
       
      )
      setKimietCha3irMontahiyat(qt);

    }
  };

  useEffect(() => {
    calculateKimietCha3irMontahiyat();
  }, [listAdh]);


  const resetkimietCha3irMontahiyat=async()=>{
    await axios.post("/api/user/updateRestDistribution",{qt:kimietCha3irMontahiyat,proxy:proxy,type:"شعير"})
    setRestO(restO-kimietCha3irMontahiyat)
    setRestDistributionO(restDistributionO+kimietCha3irMontahiyat)
    setKimietCha3irMontahiyat(0)
    const type = "شعير";
      dispatch(confirmedSMS({ proxy: proxy, type: type }));
  }

    // Fonction de mise à jour des quantités dans le backend
    const updateQuantities = async () => {
      try {
        await axios.post("/api/user/updateQuantities", {
          proxy: proxy,
          restO,
          restDistributionO
        });
        alert("Quantities updated successfully!");
      } catch (error) {
        console.error("Failed to update quantities", error);
        alert("Failed to update quantities.");
      }
    };


  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Form
        noValidate
        validated={validated}
        className="p-4 rounded text-center"
        style={{ backgroundColor: "#f8f9fa" }}
      >
        <h1 style={{ marginTop: "100px", marginBottom: "105px" }}>
          فضاء احصائيات تأكيد استلام الشعير
        </h1>

        <Form.Group style={{ marginTop: "70px" }} as={Row} className="mb-3">
          <Form.Label column md={2} className="text-end order-md-2">
            المعتمدية 
          </Form.Label>
          {user?.typeofaccount === "Approvisionnement" || user?.typeofaccount === "delegation" || user?.typeofaccount === "Unionlocal" ? 
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Select className="text-end" placeholder="ــ اختيار ــ" value={user?.dependency}>
              <option>ــ اختيار ــ </option>
              <option value="1">بنقردان</option>
              <option value="2">بني خداش</option>
              <option value="3"> جربة ميدون</option>
              <option value="4"> جربة أجيم</option>
              <option value="5">سيدي مخلوف</option>
              <option value="6"> جربة حومة السوق</option>
              <option value="7"> جرجيس</option>
              <option value="8">مدنين الجنوبية</option>
              <option value="9">مدنيـن الشمالية</option>
            </Form.Select>
          </Col> : 
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            <Form.Select className="text-end" placeholder="ــ اختيار ــ" onChange={(e) => setDependency(e.target.value)}>
              <option>ــ اختيار ــ </option>
              <option value="1">بنقردان</option>
              <option value="2">بني خداش</option>
              <option value="3"> جربة ميدون</option>
              <option value="4"> جربة أجيم</option>
              <option value="5">سيدي مخلوف</option>
              <option value="6"> جربة حومة السوق</option>
              <option value="7"> جرجيس</option>
              <option value="8">مدنين الجنوبية</option>
              <option value="9">مدنيـن الشمالية</option>
            </Form.Select>
          </Col>}
        </Form.Group>

        <Form.Group style={{ marginTop: "70px" }} as={Row} className="mb-3">
          <Form.Label column md={2} className="text-end order-md-2">
            تجار الأعلاف / النيابات
          </Form.Label>
         
          <Col md={{ span: 3, offset: 6 }} className="order-md-1">
            {user?.typeofaccount === "Approvisionnement" ? 
              <Form.Select className="text-end" placeholder="ــ اختيار ــ" value={user?.name}>
                <option>ــ اختيار ــ </option>
                {approlist?.map((el) => (
                  <option key={el._id} value={el.name}>
                    {el.name}
                  </option>
                ))}
              </Form.Select> : 
              <Form.Select className="text-end" placeholder="ــ اختيار ــ" onChange={(e) => setProxy(e.target.value)}>
                <option>ــ اختيار ــ </option>
                {approlist?.map((el) => (
                  <option key={el._id} value={el.name}>
                    {el.name}
                  </option>
                ))}
              </Form.Select>
            }
          </Col>
        </Form.Group>

        <div style={{ height: '400px', overflowY: 'auto', marginTop: '100px' }}>
          <Table
            striped
            bordered
            hover
            variant="dark"
            responsive="md"
            dir="rtl"
            style={{ marginTop: '10px' }}
          >
            <thead>
              <tr>
                <th colSpan="10">
                  <div style={{ display: 'flex' }}>
                    <input
                      type="text"
                      placeholder="ابحث حسب ب.ت.و ..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ marginBottom: '1px', width: '100%', padding: '5px', color: 'black' }}
                    />
                    <Button
                      onClick={handleSearch}
                      style={{ marginRight: '10px' }}
                    >
                      ابحث
                    </Button>
                  </div>
                </th>
              </tr>
              <tr>
                <th></th>
                <th>رقم بطاقة تعريف</th>
                <th>الاسم</th>
                <th>اللقب</th>
                <th>الهاتف</th>
                <th> مدة الاستلام </th>
                <th>كمية الشعير البعوثة </th>
                <th> تاريخ بعث الارسالية </th>
                <th>حالة الاستلام</th>
                <th> احصائيات</th>
              </tr>
            </thead>
            <tbody>
              {listAdh?.map((el, index) =>
                <tr key={el._id}>
                  <td style={{ width: '50px' }}>{index + 1}</td>
                  <td>{el.cin}</td>
                  <td>{el.name}</td>
                  <td>{el.lastname}</td>
                  <td>{el.phone}</td>
                  <td>{el.historiqueO[0]?.period ? el?.historiqueO[0]?.period : "غير محدد"}</td>
                  <td>{el.historiqueO[0]?.cota ? el?.historiqueO[0]?.cota : "غير محدد"}</td>
                  <td>{el.historiqueO[0]?.date ? affichageDate(el?.historiqueO[0]?.date) : "تواريخ قديمة"}</td>
                  <td>
                    <Button
                      variant={el?.historiqueO[0]?.confirmed ?el.historiqueO[0]?.confirmed =="reseted"? "warning" : "success" : "danger"}
                      disabled={!el?.historiqueO[0]?.confirmed}
                      style={{
                        cursor: el?.historiqueO[0]?.confirmed ? 'default' : 'not-allowed',
                        minWidth: '135px',
                        textAlign: 'center',
                      }}
                    >
                      {el?.historiqueO[0]?.confirmed ?el?.historiqueO[0]?.confirmed =="reseted"?'منتهية الصلوحية': 'تم الاستلام' : 'لم يتم الاستلام'}
                    </Button>
                  </td>
                  <td>
                    <ModalFarmerOrgeStat el={el} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div className="fixed-bottom bg-warning shadow rounded p-0" style={{ width: '100%', maxHeight: '30vh', overflowY: 'auto' }}>
  <Form.Group as={Row} className="mb-0 bg-warning shadow rounded w-100 d-flex justify-content-between align-items-center g-0">
    <Row className="w-100 d-flex justify-content-between align-items-center g-0">
    {(user?.typeofaccount === "Admin" || user?.typeofaccount === "Unionlocal") && (
        <>
          {/* Block for "restDistribution" */}
          <Col xs={12} sm={6} md={2} className="mb-0 p-1">
            <Form.Label className="text-end mb-0" style={{ fontSize: '0.5rem' }}>
              كمية الشعير المتبقية من الشاحنة بعد بعث الإرساليات
            </Form.Label>
            <Form.Control
              name="restDistribution"
              type="tel"
              placeholder={restDistributionO}
              className="text-end form-control-sm"
              disabled={user?.typeofaccount === "Unionlocal"}  // Disable for "Unionlocal"
              onChange={user?.typeofaccount === "Admin" ? (e) => setRestDistributionO(e.target.value) : undefined}  // Only Admin can change
              style={{ height: '1.5rem', marginBottom: '0' }} 
            />
            {user?.typeofaccount === "Admin" && (
              <Button onClick={updateQuantities} className="mt-2">تعديل</Button>
            )}
          </Col>

          {/* Block for "restO" */}
          <Col xs={12} sm={6} md={2} className="mb-0 p-1">
            <Form.Label className="text-end mb-0" style={{ fontSize: '0.5rem' }}>
              كمية الشعير المراد توزيعها بعد بعث الإرساليات
            </Form.Label>
            <Form.Control
              name="restO"
              type="tel"
              placeholder={restO}
              className="text-end form-control-sm"
              disabled={user?.typeofaccount === "Unionlocal"}  // Disable for "Unionlocal"
              onChange={user?.typeofaccount === "Admin" ? (e) => setRestO(e.target.value) : undefined}  // Only Admin can change
              style={{ height: '1.5rem', marginBottom: '0' }}
            />
            {user?.typeofaccount === "Admin" && (
              <Button onClick={updateQuantities} className="mt-2">تعديل</Button>
            )}
          </Col>
        </>
      )}


           

      <Col xs={12} sm={6} md={2} className="mb-0 p-1">
        <Form.Label className="text-end mb-0" style={{ fontSize: '0.5rem' }}>
          كمية الشعير الموزعة من اخر تحيين
        </Form.Label>
        <Form.Control
          name="endQuantity"
          type="tel"
          placeholder={appro ? appro[0]?.qtO || 0 : 0}
          className="text-end form-control-sm"
          disabled
          style={{ height: '1.5rem', marginBottom: '0' }}
        />
      </Col>
      {/* l'affiche nest pas important */}
      {/* <Col xs={12} sm={6} md={2} className="mb-0 p-1">
        <Form.Label className="text-end mb-0" style={{ fontSize: '0.5rem' }}>
          كمية الشعير الموزعة الاجمالية
        </Form.Label>
        <Form.Control
          name="endQuantity"
          type="tel"
          placeholder={appro ? appro[0]?.TotalOrgeDistribue || 0 : 0}
          className="text-end form-control-sm"
          disabled
          style={{ height: '1.5rem', marginBottom: '0' }}
        />
      </Col> */}
      <Col xs={12} sm={6} md={2} className="mb-0 p-1">
        <Form.Label className="text-end mb-0" style={{ fontSize: '0.5rem' }}>
          كمية الشعير المنتهية الصلاحية
        </Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            name="kimietCha3irMontahiyat"
            type="tel"
            placeholder={kimietCha3irMontahiyat}
            className="text-end me-2 form-control-sm"
            disabled
            style={{ height: '1.5rem', marginBottom: '0' }}
          />
          {(user?.typeofaccount === "Admin" || user?.typeofaccount === "Unionlocal") && (
            <Button className="d-flex justify-content-center align-items-center rounded-circle small" onClick={handleShow} style={{ height: '1.5rem', width: '1.5rem' }}>
              +
            </Button>
          )}
        </div>
      </Col>
    </Row>
  </Form.Group>

  <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>تنبيه</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      سوف يتم التخلص من الكمية المنتهية الصلاحية وإضافتها إلى الكمية المتبقية لإعادة توزيعها
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>إلغاء</Button>
      <Button variant="primary" onClick={() => {
        resetkimietCha3irMontahiyat();
        handleClose();
      }}>موافق</Button>
    </Modal.Footer>
  </Modal>
</div>


      </Form>
    </div>
  );
};

export default FarmerOrgeStat;