import React, { useState } from 'react';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const ModalFarmerOrgeStat = (props) => {
  const ADHList = useSelector(state => state.adherantReducer.StatisticADH);

  const [show, setShow] = useState(false);
  const [listAdh, setListAdh] = useState(ADHList);
  const [searchTerm, setSearchTerm] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearch = () => {
    if (searchTerm) {
      setListAdh(ADHList.filter(e => e.cin == searchTerm));
    } else {
      setListAdh(ADHList);
    }
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const affichageDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  };

  const filteredHistorique = props.el.historiqueO?.filter(e => {
    if (selectedMonth === '' && selectedYear === '') return true;

    const dateObj = new Date(e.date);
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();

    const monthMatches = selectedMonth === '' || month === parseInt(selectedMonth);
    const yearMatches = selectedYear === '' || year === parseInt(selectedYear);

    return monthMatches && yearMatches;
  });

  return (
    <div>
      <Button variant="info" onClick={handleShow} style={{minWidth: '140px', textAlign: 'center'}}>
        احصائيات الفلاح
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        className="modal-responsive"
      >
        <Modal.Header closeButton className="text-center flex-column flex-md-row align-items-center">
          <Modal.Title className="w-100 mb-3 mb-md-0">
            احصائيات الفلاح من الشعير
          </Modal.Title>
          <div className="d-flex flex-column flex-md-row w-100 justify-content-center">
            <Form.Select
              aria-label="Select Month"
              onChange={handleMonthChange}
              className="mx-md-3 mb-3 mb-md-0"
              style={{ width: '100%', maxWidth: '300px' }}
            >
              <option value="">اختر الشهر</option>
              <option value="1">جانفي</option>
              <option value="2">فيفري</option>
              <option value="3">مارس</option>
              <option value="4">أفريل</option>
              <option value="5">ماي</option>
              <option value="6">جوان</option>
              <option value="7">جويلية</option>
              <option value="8">أوت</option>
              <option value="9">سبتمبر</option>
              <option value="10">أكتوبر</option>
              <option value="11">نوفمبر</option>
              <option value="12">ديسمبر</option>
            </Form.Select>
            <Form.Select
              aria-label="Select Year"
              onChange={handleYearChange}
              className="mx-md-3 mb-3 mb-md-0"
              style={{ width: '200px' }}
              value={selectedYear}
            >
              <option value="">اختر السنة</option>
              {[2024, 2025].map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </Form.Select>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="table-responsive">
            <Table
              striped
              bordered
              hover
              variant="dark"
              responsive="sm"
              dir="rtl"
              style={{ marginTop: '10px' }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>رقم ب.ت.و</th>
                  <th>الاسم</th>
                  <th>اللقب</th>
                  <th>الهاتف</th>
                  <th>مدة الاستلام</th>
                  <th>كمية الشعير المبعوثة</th>
                  <th>تاريخ بعث الأرساليات</th>
                  <th>مكان الاستلام</th>
                  <th>حالة الاستلام</th>
                </tr>
              </thead>
              <tbody>
                {filteredHistorique?.map((e, index) => (
                  <tr key={index}>
                    <td style={{ width: '50px' }}>{index + 1}</td>
                    <td>{props.el.cin}</td>
                    <td>{props.el.name}</td>
                    <td>{props.el.lastname}</td>
                    <td>{props.el.phone}</td>
                    <td>{e.period ? e.period : "غير محدد"}</td>
                    <td>{e.cota ? e.cota : "غير محدد"}</td>
                    <td>{e.date ? affichageDate(e.date) : "تواريخ قديمة"}</td>
                    <td>{e.proxy ? e.proxy : "غير محدد"}</td>
                    <td>
                    <Button
                      variant={e.confirmed ?e.confirmed=="reseted"?"warning": "success" : "danger"}
                      disabled={!e.confirmed}
                      style={{
                        cursor: e.confirmed ? 'default' : 'not-allowed',
                        minWidth: '100px',   
                        textAlign: 'center', 
                      }}
                    >
                      {e.confirmed ?e.confirmed=="reseted"?"منتهية الصلوحية": 'تم الاستلام' : 'لم يتم الاستلام'}
                    </Button>

                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleClose}>
            اغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalFarmerOrgeStat;
